<template>
  <div class="page">
    <div class="top-detail">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div class="svg-container">
              <svg class="half-dots" id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 16">
                <g id="c"><path d="M32,16H0C0,7.16,7.16,0,16,0s16,7.16,16,16Z" style="fill:#fff;"/>
                  <path d="M76,16H44C44,7.16,51.16,0,60,0s16,7.16,16,16Z" style="fill:#fff;"/>
                  <path d="M120,16h-32c0-8.84,7.16-16,16-16s16,7.16,16,16Z" style="fill:#fff;"/>
                </g>
              </svg>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <b-container>
      <b-row v-if="post.Title">
        <b-col cols="12" lg="7" offset-lg="2">
          <h1 class="page-title">{{ post.Title }}</h1>
        </b-col>
      </b-row>

      <b-row v-if="post.Meta_data">
        <b-col cols="12" lg="7" offset-lg="2">
          <div class="page-meta-data">{{ post.Meta_data }}</div>
        </b-col>
      </b-row>

      <b-row v-if="hasNestedProperty(post,'Featured_image.formats.large.url')">
        <b-col cols="12" lg="8" offset-lg="2">
          <div class="featured-image-container">
            <img :src="imgUrlGenerate(post.Featured_image.formats.large.url)" alt="" />
          </div>
        </b-col>
      </b-row>

      <b-row v-if="post.Preface">
        <b-col cols="12" lg="7" offset-lg="2">
          <p class="page-preface vp-retain-margin">{{ post.Preface }}</p>
        </b-col>
      </b-row>

      <b-row v-if="post.Body">
        <b-col cols="12" lg="7" offset-lg="2">
          <div class="page-body" v-html="postProcessBody" />
        </b-col>
      </b-row>
    </b-container>

    <FooterMenu />

  </div>
</template>

<script>
import gql from "graphql-tag";
import FooterMenu from '../components/Footer.vue';
import mixin from '@/mixins/mixin.js';

const { VUE_APP_BACKEND_URL } = process.env;

export default {
  name: "SinglePost",
  mixins: [mixin],
  components: {
    FooterMenu
  },
  data() {
    return {
      post: {},
      seo: {},
    };
  },
  async created() {
    await this.getPosts(this.$route.params.slug);
  },
  computed: {
    postProcessBody() {
      if (!this.post || !this.post.Body) {
        return;
      }

      if (this.seo) {
        let extra = {
          createdAt: this.post.createdAt,
          updatedAt: this.post.updatedAt,
        };
        this.setSeoMeta(this.seo, extra);
      }

      let body = this.post.Body;
      const matchUploads = new RegExp('(src(set)?=["\']?|[0-9]+[wh],)(\\/uploads\\/)', 'gi');
      body = body.replaceAll(matchUploads, `$1${VUE_APP_BACKEND_URL}$3`);
      return body;

    },
  },
  methods: {
    async getPosts(slug) {
      await this.$apollo.query({
        query: gql`
          query posts($slug: String!) {
            posts(filters: { slug: { eq: $slug } }) {
              Title
              Body
              Meta_data
              Preface
              Featured_image {
                formats
              }
              createdAt
              updatedAt
              seo {
                metaTitle
                metaDescription
                keywords
                metaRobots
                structuredData
                metaViewport
                canonicalURL
                metaImage {
                  formats
                  name
                  url
                  width
                  height
                  mime
                }
                metaSocial {
                  socialNetwork
                  title
                  description
                  image {
                    formats
                    name
                    url
                    width
                    height
                    mime
                  }
                }
              }
            }
          }
        `,
        variables: {
          slug,
        }
      }).then((data) => {
        if (data.data.posts && data.data.posts.length > 0) {
          this.post = data.data.posts[0];
          if (this.post.seo) {
            this.seo = this.post.seo;
          }
        } else {
          this.post = {};
        }
      }).catch((error) => {
        console.error('Error fetching post:', error);
      });
    },
    imgUrlGenerate(link) {
      return VUE_APP_BACKEND_URL + link;
    },
  },
};
</script>


<style lang="scss">
@use '../assets/scss/variables/breakpoints';
@use '../assets/scss/variables/colors';
@use '../assets/scss/variables/sizes';
@use '../assets/scss/variables/type';

.page {
  .top-detail {
    background-color: #000;
    margin-bottom: sizes.$spacing-4xl;

    .svg-container {
      height: 0.625rem;
      position: relative;

      svg.half-dots {
        bottom: 0;
        height: 0.5rem;
        position: absolute;
        right: 0;
        width: auto;
      }
    }
  }

  .page-title {
    font-size: type.$fs-6xl;
    font-weight: type.$fw-black;
    line-height: 1;
    margin-bottom: sizes.$spacing-2xl;
  }

  .featured-image-container {
    margin-bottom: sizes.$spacing-2xl;
  }

  .page-meta-data,
  .page-preface {
    font-size: type.$fs-lg;
    font-weight: type.$fw-medium;
    line-height: type.$lh-sm;
  }

  .page-body {
    font-size: type.$fs-regular;
    line-height: type.$lh-lg;

    h2,
    h3,
    h4,
    h5,
    h6 {
      font-size: type.$fs-xl;
      font-weight: type.$fw-bold;
      line-height: type.$lh-sm;
      margin-bottom: sizes.$spacing-3xs;
    }

    a {
      color: colors.$brand-tertiary;
    }

    ul li {
      list-style-type: disc;
      list-style-position: inside;
    }

    ol li {
      list-style-position: inside;
      list-style-type: decimal;
    }

    p,
    ul,
    ol {
      font-size: inherit;
      line-height: inherit;
      margin: 0 0 sizes.$spacing-2xl;
    }

    figure {
      margin-bottom: sizes.$spacing-2xl;
    }
  }

  @media #{breakpoints.$tablet-and-above} {
    .top-detail {
      margin-bottom: sizes.$spacing-12xl;

      .svg-container {
        height: 1.3125rem;

        svg.half-dots {
          height: 1rem;
        }
      }
    }

    .page-title {
      font-size: type.$fs-9xl;
      margin-bottom: sizes.$spacing-4xl;
    }

    .featured-image-container {
      margin-bottom: sizes.$spacing-4xl;
    }

    .page-meta-data,
    .page-preface {
      font-size: type.$fs-2xl;
      line-height: type.$lh-regular;
      margin-bottom: sizes.$spacing-4xl;
    }

    .page-body {
      font-size: type.$fs-lg;

      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: type.$fs-4xl;
        line-height: type.$lh-xs;
      }
    }
  }
}
</style>
