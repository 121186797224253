<template>
  <div>
    <HomeText />
    <SearchForm
        @submit-search-form="submitSearchForm"
        @reset-form="resetForm"
        :key="searchFormKey"
        ref="updateSearchParameters"
    />
    <b-container v-if="events.length" class="vp-desktop-no-padding">
      <b-row>
        <b-col cols="12" lg="8" offset-lg="2">
          <EventCarousel :ticketingEvents="ticketingEvents" />
        </b-col>
      </b-row>
    </b-container>

    <b-container v-if="events.length" class="vp-desktop-no-padding">
      <b-row>
        <b-col cols="12" lg="8" offset-lg="2">
          <EventList :events="events" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="load-more-button-container">
            <button v-if="loadMore" @click="loadMoreEvents" class="vp-button vp-tertiary-ghost-btn">
              Meer evenementen
            </button>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <div v-else id="vp-search-results">
      <SearchResults
        :events='searchResults'
        :totalResults='totalResults'
      />
      <b-container>
        <b-row>
          <b-col cols="12">
            <button
              v-on:click="loadMoreEventsSearch"
              v-if="searchLoadMore"
              class="vp-button vp-tertiary-ghost-btn"
            >
              Meer evenementen
            </button>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <FooterMenu />
  </div>
</template>

<script>
import EventList from "@/components/EventList";
import FooterMenu from '@/components/Footer.vue';
import SearchResults from "@/components/SearchResults";
import HomeText from "@/components/HomeText";
import SearchForm from "@/components/searchForm";
import EventCarousel from "@/components/EventCarousel.vue"; // Import the EventCarousel component
import store from '../store/store';

import mixin from '@/mixins/mixin.js';

export default {
  // eslint-disable-next-line
  name: "Home",
  mixins: [mixin],
  components: {
    EventCarousel,
    EventList,
    FooterMenu,
    HomeText,
    SearchForm,
    SearchResults,
  },
  data() {
    return {
      events: [],
      loadMore: false,
      page: 1,
      searchFormKey: 0,
      searchResults: [],
      resultsPerPage: 20,
      resultsPerPageSearch: 8,
      searchPage: 1,
      searchLoadMore: true,
      totalResults: 0,
      ticketingEvents: [],
    };
  },
  methods: {
    searchEvents() {
      this.searchPage = 1;
      this.loadMore = false;
      this.events = [];
      const filters = store.getConfig('search', 'filters');

      this.getEvents(filters, this.searchPage, 100000).then((response) => {
        this.searchResults = response.events;

        this.totalResults = response.totalResults;
        this.searchLoadMore = this.totalResults > this.searchResults.length;
      });
    },
    initForm() {
      this.searchLoadMore = this.totalResults > this.searchResults.length;

      // If set, we search in the categories set in the config.
      /*if (Array.isArray(store.state.config.categories.query)
          && store.state.config.categories.query.length
      ) {
        filters.categoryIds = store.state.config.categories.query;
      }*/

      // Get latest events.
      this.getEvents({}, 1, this.resultsPerPage).then((response) => {
        this.events = response.events;
        this.loadMore = response.totalResults > (this.resultsPerPage * this.page);
      });

      this.getEvents({ ticketSalesByVanPlan: true } ,1,16).then((res) => {
        this.ticketingEvents = res.events;
      });
    },
    loadMoreEvents() {
      this.page += 1;
      const filters = store.getConfig('search', 'filters');
      filters.searchWord = '';
      store.setConfig('search', 'filters', filters);

      this.getEvents({}, this.page, this.resultsPerPage).then((response) => {
        this.events.push(...response.events);
        this.loadMore = response.totalResults > (this.resultsPerPage * this.page);
      });
    },
    loadMoreEventsSearch() {
      this.searchPage += 1;
      const filters = store.getConfig('search', 'filters');

      this.getEvents(filters, this.searchPage, this.resultsPerPageSearch).then((response) => {
        this.searchResults.push(...response.events);
        this.searchLoadMore = this.totalResults > this.searchResults.length;
      });
    },
    resetForm() {
      // Reset the form by updating searchFormKey so that it rerenders.
      this.searchFormKey += 1;
      this.loadMore = true;
      store.setConfig('search', 'filters', { ticketSalesByVanPlan: true });

      this.initForm();
    },
    submitSearchForm(filters) {
      this.searchPage = 1;
      store.setConfig('search', 'filters', filters);

      this.searchEvents();
    },
  },
  mounted() {
    const filters = store.getConfig('search', 'filters');
    const keys = Object.keys(filters);
    if (keys.length === 1 && keys[0] === 'ticketSalesByVanPlan') {
      this.initForm();
    } else {
      this.$refs.updateSearchParameters.setSearchInputData(filters);
      this.submitSearchForm(filters);
    }

    this.setSeoMeta(store.getConfig('homepageseo', 'seo'));
  },
};

</script>

<style lang="scss" scoped>
@use '../assets/scss/variables/breakpoints';
@use '../assets/scss/variables/type';

$gap: 2rem;
.b-carousel-control-prev,
.b-carousel-control-next {
  background-color: #007bff;
  border-radius: 50%;
  border: 5px;
  color: #fff;
  font-size: 20px;
  padding: 10px;

  .b-carousel-control-prev:hover,
  .b-carousel-control-next:hover {
    background-color: #0056b3;
  }

  .b-carousel-control-prev:focus,
  .b-carousel-control-next:focus {
    outline: none;
    box-shadow: none;

    .vp-button {
      display: block;
      font-size: type.$fs-2xl;
      font-weight: type.$fw-black;
      height: auto;
      padding: type.$fs-xl;
      text-transform: uppercase;

      @media #{breakpoints.$tablet-and-above} {
        width: auto;
      }
    }
  }
}

.load-more-button-container {
  display: flex;
  justify-content: center;
}

</style>
