<template>
  <ul class="vp-event-list">
    <li
      v-for="(item, index) in events"
      :index="index"
      :key="item.id"
      class="vp-event-list-item "
    >
      <EventFeatured :event="item"></EventFeatured>
    </li>
  </ul>
</template>

<script>
import EventFeatured from "@/components/EventFeatured";

export default {
  name: "EventList",
  components: {
    EventFeatured,
  },
  props: {
    events: {
      type: Array,
      required: true,
    }
  },
};
</script>

<style lang="scss">
@use '../assets/scss/variables/breakpoints';
@use '../assets/scss/variables/sizes';

.vp-event-list {
  display: flex;
  flex-wrap: wrap;
  gap: sizes.$spacing-5xl;
  margin-bottom: sizes.$spacing-7xl;

  .vp-event-list-item {
    width: 100%;
  }

  @media #{breakpoints.$tablet-and-above} {
    margin-bottom: sizes.$spacing-12xl;

    .vp-event-list-item {
      width: calc(50% - (#{sizes.$spacing-5xl} / 2));
    }
  }
}
</style>
