<template>
  <div id="vp-search-form">
    <b-container>
      <b-row class="vp-search-row">
        <b-col cols="12">
          <svg class="half-dots outside" id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 16">
            <g id="c"><path d="M32,16H0C0,7.16,7.16,0,16,0s16,7.16,16,16Z" />
              <path d="M76,16H44C44,7.16,51.16,0,60,0s16,7.16,16,16Z" />
              <path d="M120,16h-32c0-8.84,7.16-16,16-16s16,7.16,16,16Z" />
            </g>
          </svg>

          <svg class="half-dots inside" id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 16">
            <g id="c"><path d="M32,16H0C0,7.16,7.16,0,16,0s16,7.16,16,16Z" />
              <path d="M76,16H44C44,7.16,51.16,0,60,0s16,7.16,16,16Z" />
              <path d="M120,16h-32c0-8.84,7.16-16,16-16s16,7.16,16,16Z" />
            </g>
          </svg>
        </b-col>
        <b-col cols="12" lg="8" offset-lg="2">
          <div class="search-form-container">
            <form @submit.prevent="getSearchResults" class="vp-search-form">
              <h2 class="vp-search-title">Vind de beste tickets</h2>
              <div class="vp-form-group">
                <div class="vp-input-container vp-input-xl">
                  <input
                    type="text"
                    class="vp-search-input"
                    placeholder="Voer een zoekterm in"
                    v-model="searchText"
                    @input="inputValidate($event)"
                    id="vp-search-text"
                  >
                </div>

                <div class="vp-input-container vp-input-xl" >
                  <input
                    type="text"
                    placeholder="Plaatsnaam"
                    v-model="city"
                    @input="inputValidate($event)"
                    id="vp-filter-city"
                  >
                </div>
              </div>

              <div class="vp-form-group reverse-desktop" v-if="showFilterToggle">
                <div class="vp-input-container vp-input-xl vp-button-container">
                  <button
                      v-if="showFilterToggle"
                      class="vp-full-width"
                      :disabled="(this.searchText == '' && this.city == '')"
                      id="vp-search-button"
                  >
                    ZOEK
                  </button>
                </div>

                <div class="vp-input-container vp-input-xl 2" v-if="showFilterToggle">
                  <div
                      id="vp-search-filters"
                      :class="{'vp-filter-active': !showFilterToggle}"
                      @click="doFilterToggle()"
                  >
                    <span>
                      Open het filter
                    </span>

                    <!-- eslint-disable -->
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width="92px" height="92px" viewBox="0 0 92 92" enable-background="new 0 0 92 92" xml:space="preserve">
                        <path id="XMLID_467_" d="M46,63c-1.1,0-2.1-0.4-2.9-1.2l-25-26c-1.5-1.6-1.5-4.1,0.1-5.7c1.6-1.5,4.1-1.5,5.7,0.1l22.1,23l22.1-23
                         c1.5-1.6,4.1-1.6,5.7-0.1c1.6,1.5,1.6,4.1,0.1,5.7l-25,26C48.1,62.6,47.1,63,46,63z"/>
                    </svg>
                    <!-- eslint-enable -->
                  </div>
                </div>
              </div>

              <div
                  v-if="!showFilterToggle"
                  id="vp-search-filters-form"
                  class="vp-search-filters-container vp-form-group"
              >
                <div class="vp-input-container vp-input-xs">
                  <select
                    v-model="radius"
                    @input="inputValidate($event)"
                    :disabled="this.city == ''"
                    id="vp-filter-distance"
                  >
                    <option value="">0 km</option>
                    <option value="5">5 km</option>
                    <option value="10">10 km</option>
                    <option value="20">20 km</option>
                    <option value="50">50 km</option>
                    <option value="334">meer</option>
                  </select>
                </div>

                <div class="vp-input-container vp-input-md">
                  <select
                    v-model="pricerange"
                    @input="inputValidate($event)"
                  >
                    <option value="">Prijs</option>
                    <option value="0_0">Gratis</option>
                    <option value="0_10">0 tot 10 euro</option>
                    <option value="10_20">10 tot 20 euro</option>
                    <option value="20_50">20 tot 50 euro</option>
                    <option value="50">meer</option>
                  </select>
                </div>

                <div class="vp-input-container vp-input-sm vp-date-container">
                  <Datepicker
                      v-model="startdate"
                      inputFormat="dd-MM-yyyy"
                      placeholder="Begindatum"
                      id="vp-filter-start-date"
                      :lowerLimit="lowerLimit"
                      @selected="startDateSelected"
                  />
                </div>

                <div class="vp-input-container vp-input-sm vp-date-container">
                  <Datepicker
                      v-model="enddate"
                      inputFormat="dd-MM-yyyy"
                      placeholder="Einddatum"
                      id="filter-end-date"
                      :lowerLimit="lowerLimit"
                      @selected="endDateSelected"
                  />
                </div>
              </div>

              <div v-if="!showFilterToggle" class="vp-form-group">
                <div v-if="categories.length" class="vp-input-container vp-input-xl">
                  <select
                    v-model="category"
                    @input="inputValidate($event)"
                    :disabled="categories.length === 0"
                  >
                    <option value="">Filter op categorie</option>
                    <option
                      v-for="(cat, index) in categories"
                      :value="cat.id"
                      :key="index"
                    >
                      {{ cat.name }}
                    </option>
                  </select>
                </div>

                <div class="vp-input-container vp-button-container vp-input-xl">
                  <button :disabled="!filtersActive">ZOEK</button>
                </div>
              </div>

              <div class="vp-form-group">
                <div class="vp-input-container vp-input-xl">
                  <div
                      id="vp-search-filters"
                      :class="{'vp-filter-active': !showFilterToggle}"
                      @click="doFilterToggle()"
                      v-if="!showFilterToggle"
                  >
                    <span>
                      Sluit het filter
                    </span>

                    <!-- eslint-disable -->
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width="92px" height="92px" viewBox="0 0 92 92" enable-background="new 0 0 92 92" xml:space="preserve">
                        <path id="XMLID_467_" d="M46,63c-1.1,0-2.1-0.4-2.9-1.2l-25-26c-1.5-1.6-1.5-4.1,0.1-5.7c1.6-1.5,4.1-1.5,5.7,0.1l22.1,23l22.1-23
                         c1.5-1.6,4.1-1.6,5.7-0.1c1.6,1.5,1.6,4.1,0.1,5.7l-25,26C48.1,62.6,47.1,63,46,63z"/>
                    </svg>
                    <!-- eslint-enable -->
                  </div>
                </div>
                <div class="vp-input-container vp-input-xl vp-button-container">
                  <a v-on:click="resetForm" class="vp-button vp-reset-btn">
                    RESET
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g fill="none" fill-rule="evenodd">
                        <g fill="#FFF">
                          <g>
                            <g>
                              <!-- eslint-disable-next-line -->
                              <path d="M9 6c1.38 0 2.63.56 3.54 1.46L10 10h6V4l-2.05 2.05C12.68 4.78 10.93 4 9 4c-3.53 0-6.43 2.61-6.92 6H4.1C4.56 7.72 6.58 6 9 6zm5.64 9.14c.66-.9 1.12-1.97 1.28-3.14H13.9c-.46 2.28-2.48 4-4.9 4-1.38 0-2.63-.56-3.54-1.46L8 12H2v6l2.05-2.05C5.32 17.22 7.07 18 9 18c1.55 0 2.98-.51 4.14-1.36L18 21.49 19.49 20l-4.85-4.86z" transform="translate(-264 -314) translate(20 302) matrix(-1 0 0 1 268 12)"/>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </a>
                </div>
              </div>
            </form>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Datepicker from 'vue3-datepicker';
import moment from 'moment';
import axios from 'axios';
import mixin from '../mixins/mixin';
import store from '../store/store';

export default {
  name: 'SearchForm',
  mixins: [mixin],
  components: {
    Datepicker
  },
  data () {
    return {
      searchText: '',
      startdate: null,
      enddate: null,
      category: '',
      city: '',
      radius: '',
      pricerange: '',
      showFilterToggle: true,
      categories: [],
      lowerLimit: new Date(Date.now()),
    };
  },
  computed: {
    filtersActive() {
      // If any fields have a value set to them, enable the filter button
      return this.searchText || this.startdate || this.enddate || this.category || this.city || this.radius || this.pricerange;
    }
  },
  methods: {
    startDateSelected(date) {
      if (date) {
        document.querySelector('#vp-filter-start-date').classList.add('vp-valid-input');
      } else {
        document.querySelector('#vp-filter-start-date').classList.remove('vp-valid-input');
      }
    },
    endDateSelected(date) {
      if (date) {
        document.querySelector('#filter-end-date').classList.add('vp-valid-input');
      } else {
        document.querySelector('#filter-end-date').classList.remove('vp-valid-input');
      }
    },
    setSearchInputData(filters) {
      let range = '';
      if (filters.priceRange?.fromPrice) {
        if (filters.priceRange.toPrice) {
          range = filters.priceRange.fromPrice + '_' + filters.priceRange.toPrice;
        } else {
          range = filters.priceRange.fromPrice
        }
      }
      const startDateExists = filters.period?.startDate ? new Date(filters.period?.startDate) : null;
      this.searchText = filters.searchWord;
      this.startdate = startDateExists;
      this.enddate = filters.period?.endDate ? new Date(filters.period.endDate) : startDateExists;
      this.category = filters.categoryIds ? filters.categoryIds : '';
      this.city = store.getConfig('search', 'city');
      this.radius = filters.locationWithRadius?.searchRadius ? filters.locationWithRadius?.searchRadius : '';
      this.pricerange = range;
      this.showFilterToggle = store.getConfig('search', 'showFilterToggle');
      this.categories = [];
    },
    getSearchResults() {
      const filters = {};
      // const gtagPayload = {};

      // Store city in the store to be able to refetch from store to refill the search terms
      store.setConfig('search', 'city', this.city ? this.city : '');

      // Search text.
      filters.searchWord = this.searchText;

      // gtagPayload.searchWord = filters.searchWord;

      // Add start and end date filter.
      if (this.startdate || this.enddate) {
        filters.period = {};

        if (this.startdate) {
          filters.period.startDate = moment(this.startdate).format('YYYY-MM-DD');
          //gtagPayload.startDate = filters.period.startDate;
        }

        if (this.enddate) {
          filters.period.endDate = moment(this.enddate).format('YYYY-MM-DD');
          //gtagPayload.endDate = filters.period.endDate;
        } else if (this.startdate) {
          // If no enddate use startdate as enddate so that we search only on this day.
          filters.period.endDate = moment(this.startdate).format('YYYY-MM-DD');
          //gtagPayload.endDate = filters.period.endDate;
        }
      }

      // Add category filter.
      /*if (this.category) {
        // Check if this category is in the categories set in the config.
        const configCategory = _.find(
            store.state.config.categories.filters,
            (o) => o.id === this.category,
        );

        if (configCategory) {
          // gtagPayload.category = configCategory.name;
        }

        // If the selected category has subcategories search in those.
        if (configCategory && configCategory.subs && configCategory.subs.length) {
          // Search in the sub categories as set in the config.
          const subs = _.map(configCategory.subs, 'id');
          filters.categoryIds = subs.map((o) => parseInt(o, 10));
        } else {
          filters.categoryIds = parseInt(this.category, 10);
        }
      }*/

      if (this.category) {
          filters.categoryIds = parseInt(this.category, 10);
      }

      // Add price range filter.
      if (this.pricerange) {
        if (this.pricerange.indexOf('_') === -1) {
          filters.priceRange = {
            fromPrice: this.pricerange,
          };
        } else {
          const ranges = this.pricerange.split('_');
          filters.priceRange = {
            fromPrice: ranges[0],
            toPrice: ranges[1],
          };

          // gtagPayload.fromPrice = filters.priceRange.fromPrice;
          // gtagPayload.toPrice = filters.priceRange.toPrice;
        }
      }

      // If city and radius then search on radius.
      if (this.city && this.radius) {
        // gtagPayload.city = this.city;
        // gtagPayload.radius = this.radius;

        // Add lat lon based on city.
        axios
            .get(`https://nominatim.openstreetmap.org/search?format=json&country=nl&city=${this.city}`)
            .then((response) => {
              if (
                  response.data.length > 0
                  && Object.prototype.hasOwnProperty.call(response.data[0], 'lat')
                  && Object.prototype.hasOwnProperty.call(response.data[0], 'lon')
              ) {
                // Add radius filter
                const radius = (this.radius) ? parseInt(this.radius, 10) : 4;
                filters.locationWithRadius = {
                  latitude: response.data[0].lat,
                  longitude: response.data[0].lon,
                  searchRadius: radius,
                };

                // Emit filters when lon/lat call is finished.
                this.$emit('submit-search-form', filters);
              } else {
                // Radius not found, just use the city field.
                if (this.city) {
                  filters.locationCityName = this.city;
                }
                this.$emit('submit-search-form', filters);
              }
            });
      } else {
        if (this.city) {
          filters.locationCityName = this.city;
          // gtagPayload.city = this.city;
        }
        this.$emit('submit-search-form', filters);
      }

      // Send event to gtag.
      //this.track({}, 'search', gtagPayload);
    },
    resetForm() {
      // Reset the search form filter by updating the key.
      this.$emit('reset-form');
    },
    doFilterToggle() {
      this.showFilterToggle = !this.showFilterToggle;
      store.setConfig('search', 'showFilterToggle', this.showFilterToggle);
    },
    dateFormat(date) {
      return moment(date).format('D-M-YYYY');
    },
  },
  created() {
    this.getCategories().then((response) => {
      this.categories = response;
    });
  },
};
</script>

<style lang="scss">
@use '../assets/scss/variables/breakpoints';
@use '../assets/scss/variables/colors';
@use '../assets/scss/variables/misc';
@use '../assets/scss/variables/sizes';
@use '../assets/scss/variables/type';

#vp-search-form {
  background: colors.$brand-tertiary;
  color: colors.$brand-tertiary-text;
  margin-bottom: sizes.$spacing-5xl;
  position: relative;

  [data-external="1"] & {
    margin-bottom: sizes.$spacing-2xl;
  }

  .vp-search-row {
    position: relative;

    svg.half-dots {
      height: 0.5rem;
      position: absolute;
      right: 1rem;
      width: auto;

      &.outside {
        bottom: 100%;
        fill: colors.$brand-tertiary;
      }

      &.inside {
        fill: #fff;
        bottom: 0;
      }
    }
  }

  .search-form-container {
    padding: sizes.$spacing-lg 0 sizes.$spacing-default;
    position: relative;
  }

  .vp-search-title {
    font-size: type.$fs-6xl;
    font-weight: type.$fw-black;
    line-height: type.$lh-sm;
    margin-bottom: sizes.$spacing-3xl;
  }

  .vp-form-group {
    column-gap: sizes.$spacing-5xl;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: sizes.$spacing-2xl; // Equal to row-gap
    row-gap: sizes.$spacing-2xl;
    width: 100%;

    .vp-input-container {
      width: 100%;

      &.vp-button-container {
        a,
        button {
          display: block;
          width: 100%;
        }
      }

      input,
      select,
      textarea,
      .vdp-datepicker {
        width: 100%;
      }
    }
  }

  .vdp-datepicker .vp-close-datepicker { // Custom datepicker icon
    background-image: url('#{misc.$icon-cross}');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 1rem;
    width: 1rem;
  }

  @media #{breakpoints.$tablet-and-above} {
    [data-external="1"] & {
      margin-bottom: sizes.$spacing-9xl;
    }

    .vp-search-row {
      svg.half-dots {
        height: 1rem;
        right: 0;
      }
    }

    .search-form-container {
      padding: sizes.$spacing-8xl 0 sizes.$spacing-2xl;
    }

    .vp-search-title {
      font-size: 2.5625rem;
      margin-bottom: 1.875rem;
    }

    .vp-form-group {
      flex-wrap: nowrap;
      margin-bottom: 0;

      &.reverse-desktop {
        flex-direction: row-reverse;
      }

      .vp-input-container {
        margin-bottom: sizes.$spacing-2xl;

        &.vp-input-xl {
          width: 50%;
        }

        &.vp-input-lg {
          width: 31.2%;
        }

        &.vp-input-md {
          width: 35%
        }

        &.vp-input-sm {
          width: 25%;
        }

        &.vp-input-xs {
          width: 15.5%;
        }
      }
    }
  }
}

#vp-search-filters {
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  -webkit-user-select: none;
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: type.$fs-lg;
  font-weight: type.$fw-medium;
  line-height: type.$lh-lg;
  margin-bottom: sizes.$spacing-default;
  user-select: none;

  &.vp-filter-active svg {
    transform: rotate(180deg);
  }

  svg {
    color: inherit;
    fill: currentColor;
    flex-shrink: 0;
    height: 1em;
    margin: 0 sizes.$spacing-3xs 0 sizes.$spacing-xs;
    width: 1em;
  }

  @media #{breakpoints.$desktop-and-above} {
    justify-content: flex-start;
    margin-bottom: sizes.$spacing-default;

    [data-external="1"] & {
      font-weight: bold;
    }
  }
}

#vp-search-filters-form {
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  &.vp-active {
    display: flex;
  }

  .vdp-datepicker {
    color: #000; // If this inherits a lighter color it becomes impossible to read

    .vdp-datepicker__clear-button {
      position: absolute;
      right: 18px; // Should not scale with font-size
      height: 100%;
      display: inline-flex;
      align-items: center;
    }
  }
}
</style>
