<template>
  <div v-if="event.location" class="vp-contact">
    <span v-if="event.location.name" class="vp-contact-title vp-h2">{{ event.location.name }}</span>

    <p v-if="street()" class="vp-contact-street">{{ street() }}</p>

    <p
        v-if="event.location.postalCode && event.location.cityName"
        class="vp-contact-postal-city"
    >
      {{ event.location.postalCode }} {{ event.location.cityName}}
    </p>

    <p v-if="event.location.phoneNumber" class="vp-contact-phone">
      {{ event.location.phoneNumber }}
    </p>

    <p v-if="event.location.emailAddress" class="vp-contact-mail">
      {{ event.location.emailAddress }}
    </p>

    <p v-if="event.location.url" class="vp-contact-url">
      <a :href="formatUrl(event.location.url)">{{ event.location.url }}</a>
    </p>
  </div>
</template>

<script>
import mixin from '@/mixins/mixin';

export default {
  name: 'eventAddress',
  mixins: [mixin],
  props: {
    event: Object,
  },
  methods: {
    street() {
      const parts = ['streetName', 'houseNumber', 'houseNumberAddition'];
      const result = [];

      Object.keys(this.event.location).forEach((val) => {
        if (parts.indexOf(val) !== -1 && this.event.location[val]) {
          result.push(this.event.location[val].trim());
        }
      });

      return result.join(' ');
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../assets/scss/variables/sizes';

.vp-contact {
  margin-bottom: sizes.$spacing-6xl;

  .vp-contact-url,
  .vp-contact-mail,
  .vp-contact-phone,
  .vp-contact-street,
  .vp-contact-postal-city {
    color: inherit;
    margin-bottom: 0;
  }

  .vp-contact-title {
    margin-bottom: sizes.$spacing-default;
  }
}
</style>
