<template>
	<div class="home-header">
    <b-container>
      <b-row>
        <b-col cols="12" lg="8" offset-lg="2">
          <h2>{{ title }}</h2>
          <div v-html="text"></div>
        </b-col>
      </b-row>
    </b-container>
	</div>
</template>

<script>
import store from '../store/store';

export default {
	name: "HomeText",
	data() {
		return {
      title: store.getConfig('homepage', 'title'),
			text: store.getConfig('homepage', 'text'),
		};
	},
};
</script>

<style lang="scss">
@use '../assets/scss/variables/breakpoints';
@use '../assets/scss/variables/sizes';
@use '../assets/scss/variables/type';

.home-header {
  background-color: #000;
  color: #fff;
  padding: sizes.$spacing-6xl 0 sizes.$spacing-10xl;

  h2 {
    font-size: type.$fs-6xl;
    font-weight: type.$fw-black;
    margin-bottom: sizes.$spacing-xl;
  }

  p {
    font-size: type.$fs-lg;
    font-weight: type.$fw-medium;
    line-height: type.$lh-sm;
    margin: 0;
  }

  @media #{breakpoints.$tablet-and-above} {
    padding: sizes.$spacing-lg 0 sizes.$spacing-2xl;

    h2 {
      font-size: type.$fs-9xl;
      margin-bottom: sizes.$spacing-5xl;
    }

    p {
      font-size: type.$fs-2xl;
      line-height: type.$lh-regular;
    }
  }
}
</style>
