<template>
  <ul class="footer-menu">
    <li v-for="menuItem in menu" :key="menuItem.id">
      <router-link v-if="menuItem.Slug" :to="menuItem.Slug">{{ menuItem.Title }}</router-link>
      <button v-else-if="menuItem.onclick" :onclick="menuItem.onclick">{{ menuItem.Title }}</button>
    </li>
  </ul>
</template>

<script>
export default {
  name: "FooterMenu",
  props: {
    menu: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@use '../assets/scss/variables/breakpoints';

.footer-menu {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    a,
    button {
      background-color: transparent;
      border: none;
      box-shadow: none;
      color: #fff;
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 1;
      text-decoration: none;
    }
  }

  @media #{breakpoints.$tablet-and-above} {
    li a,
    li button {
      font-size: 1.5rem;
    }
  }
}
</style>
