<template>
  <div class="footer-text-container">
    <svg class="half-dots" id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 16">
      <g id="c"><path d="M32,16H0C0,7.16,7.16,0,16,0s16,7.16,16,16Z" style="fill:#fff;"/>
        <path d="M76,16H44C44,7.16,51.16,0,60,0s16,7.16,16,16Z" style="fill:#fff;"/>
        <path d="M120,16h-32c0-8.84,7.16-16,16-16s16,7.16,16,16Z" style="fill:#fff;"/>
      </g>
    </svg>
    <div class="footer-text" v-if="text" v-html="text"></div>
    <div class="image-container">
      <router-link to="/">
        <img :src="logo" alt="" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
	name: "FooterText",
	props: {
    text: {
      type: String,
      default: null,
    },
    logo: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
@use '../assets/scss/variables/breakpoints';

.footer-text-container {
  display: flex;
  flex-direction: column;
  padding: 1.5625rem 0 2rem;
  position: relative;
  row-gap: 1.5625rem;

  svg.half-dots {
    bottom: 100%;
    height: 0.5rem;
    position: absolute;
    right: 0;
    width: auto;
  }

  .footer-text {
    font-size: 0.875rem;
    line-height: 1;
    margin: 0;
  }

  .image-container {
    flex-shrink: 0;
    max-width: 55%;

    img {
      display: block;
      max-width: 100%;
    }
  }

  @media #{breakpoints.$tablet-and-above} {
    column-gap: 4.5625rem;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 2.5rem 0 3.75rem;

    svg.half-dots {
      height: 1rem;
    }

    .footer-text {
      font-size: 1.125rem;
    }
  }
}
</style>
