<template>
  <div>
    <div v-if="image.url" class="vp-event-images-list">
      <figure>
        <img
            :src="image.url"
            :title="image.copyright"
        />
        <div
            v-if="image.copyright"
            class="vp-featured-image-credit"
        >
          <span>Foto: {{ image.copyright }}</span>
        </div>
      </figure>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'eventImages',
  props: {
    images: Array,
  },
  data() {
    return {
      image: {
        data: {
          url: '',
          copyright: '',
        },
      },
    };
  },
  created() {
    // Set the image
    if (this.images.length > 1) {
      // Get a random image but not the first.
      this.image = this.images[_.random(1, this.images.length - 1)];
    }
  },
};
</script>

<style lang="scss">
@use '../assets/scss/variables/colors';
@use '../assets/scss/variables/sizes';
@use '../assets/scss/variables/type';

.vp-event-images-list {
  .vp-featured-image-credit {
    color: colors.$quiet;
    font-family: type.$ff-standard;
    font-size: type.$fs-sm;
    line-height: 1;
    margin-top: sizes.$spacing-default;
  }

  img {
    display: block;
    max-width: 100%;
  }
}
</style>
