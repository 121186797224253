<template>
  <header id="site-header">
    <div class="egg-trigger" @click="handleEgg" />
    <b-container>
      <b-row>
        <b-col cols="12">
          <nav class="header-navigation">
            <router-link v-if="logoUrl" class="header-logo-container" :class="{'contains-egg': egg}" to="/">
              <img v-if="!egg" :src="logoUrl" alt="" />
              <svg v-else class="oc-tearable" width="311" height="60" viewBox="0 0 311 60" xmlns="http://www.w3.org/2000/svg">
                <g fill-rule="nonzero" fill="none">
                  <path d="M59.674 56.768v-3.99a2.99 2.99 0 0 1 0-5.978v-3.99a3 3 0 0 1 0-5.989v-3.99a3 3 0 0 1 0-5.989v-3.99a2.99 2.99 0 0 1 0-5.978v-3.99a3 3 0 0 1 0-5.99V2.906A2.979 2.979 0 0 1 56.705 0H0v59.674h56.705a2.979 2.979 0 0 1 2.969-2.906z" fill="#00AAD2"/>
                  <path class="oc-tear" d="M307.368 9.884a2.99 2.99 0 0 1 2.98-2.99V2.906A2.979 2.979 0 0 1 307.367 0H62.653a2.99 2.99 0 0 1-2.98 2.905v3.99a3 3 0 0 1 0 5.99v3.957a2.99 2.99 0 1 1 0 5.98v4.02a3 3 0 0 1 0 5.99v4.01a3 3 0 0 1 0 5.99v3.99a2.99 2.99 0 0 1 0 5.978v3.99a2.99 2.99 0 0 1 2.98 2.905h244.715a2.979 2.979 0 0 1 2.969-2.906v-4.01a2.99 2.99 0 1 1 0-5.979v-3.99a3 3 0 0 1 0-5.989v-3.99a3 3 0 0 1 0-5.989v-3.99a2.99 2.99 0 1 1 0-5.978v-3.99a2.99 2.99 0 0 1-2.969-3z" fill="#00101A"/>
                  <path class="oc-tear oc-tear-text" d="m90.074 10.19-2.59 26.052-2.442-26.053h-8.758l5.348 39.285h11.842l5.358-39.285h-8.758zm12.442 0-4.442 39.284h8.42l.538-5.916h4.39l.62 5.916h8.421l-4.358-39.285h-13.59zm5.158 26.22 1.158-18.842h.79l1.157 18.843h-3.105zm28.179-7.42-4-18.8h-8.937v39.284h8.495V29.82l4.378 19.653h8.422V10.189h-8.422l.064 18.8zm30.231-16.285a7.768 7.768 0 0 0-3.01-1.926 12.168 12.168 0 0 0-3.969-.59h-11.473v39.285h8.536V33.926h3.527a10.895 10.895 0 0 0 4.452-.81 6.021 6.021 0 0 0 2.8-2.4 11.01 11.01 0 0 0 1.4-3.663 25.81 25.81 0 0 0 .4-4.948c.04-1.927-.186-3.85-.673-5.716a8.484 8.484 0 0 0-1.99-3.684zm-6 11.274a4.41 4.41 0 0 1-.337 1.368 4.853 4.853 0 0 1-.484.842c-.185.22-.436.375-.716.443-.27.083-.55.14-.831.168-.35.02-.702.02-1.053 0h-.495v-9.474h.969a2.61 2.61 0 0 1 2.347 1.053 6.842 6.842 0 0 1 .684 3.6c0 .768-.031 1.442-.084 2zm19.769-13.79h-8.685v39.285h17.61V41.82h-8.925V10.19zm14.894 0-4.442 39.285h8.421l.537-5.916h4.39l.62 5.916h8.422l-4.358-39.285h-13.59zm5.158 26.222 1.158-18.843h.79l1.158 18.843h-3.106zm28.169-7.422-3.99-18.8h-8.937v39.285h8.495V29.82l4.337 19.653h8.484V10.189h-8.42l.03 18.8zm11.452 20.485h8.832v-7.369h-8.832v7.369zm24.79-20.485-4-18.8h-8.927v39.285h8.485V29.82l4.337 19.653h8.494V10.189h-8.42l.03 18.8zm20.473 12.832V10.242h-8.684v39.232h17.58V41.82h-8.896z" fill="#FFF"/>
                  <circle fill="#00101A" cx="28.347" cy="29.842" r="20.884"/>
                  <circle fill="#FFF" cx="28.347" cy="29.842" r="15.663"/>
                  <circle fill="#EF7D00" cx="28.347" cy="29.842" r="3.874"/>
                  <path fill="#00AAD2" d="M34.61 29.842H22.085l6.263-14.62z"/>
                  <path fill="#00101A" d="M22.084 29.842h12.527l-6.264 14.621z"/>
                  <circle fill="#FFF" cx="28.347" cy="29.842" r="2.084"/>
                </g>
              </svg>
            </router-link>
            <template v-if="menu.Menu.length">
              <div class="header-menu-container" :class="{ 'menu-open': menuVisible }">
                <svg class="full-dots" width="497" height="17" viewBox="0 0 497 17" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8 8.5 508 9" stroke="#00AAD2" stroke-width="16" fill="none" fill-rule="evenodd"
                        stroke-dasharray="0,32" stroke-linecap="round"/>
                </svg>
                <ul class="header-menu">
                  <li v-for="item in menu.Menu" :key="item.id" @click="toggleMenu">
                    <router-link v-if="item.Slug" :to="item.Slug">{{ item.Title }}</router-link>
                    <button v-else-if="item.onclick" @click="handleMenuClick(item.onclick)">{{ item.Title }}</button>
                  </li>
                </ul>
                <div class="half-dots-container">
                  <svg class="half-dots" id="b" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 16">
                    <g id="c"><path d="M32,16H0C0,7.16,7.16,0,16,0s16,7.16,16,16Z" style="fill:#fff;"/>
                      <path d="M76,16H44C44,7.16,51.16,0,60,0s16,7.16,16,16Z" style="fill:#fff;"/>
                      <path d="M120,16h-32c0-8.84,7.16-16,16-16s16,7.16,16,16Z" style="fill:#fff;"/>
                    </g>
                  </svg>
                </div>
              </div>
              <button
                id="header-menu-toggle"
                :class="{'toggled-open': menuVisible}"
                @click="toggleMenu"
              >
                <span></span>
              </button>
            </template>
          </nav>
        </b-col>
      </b-row>
    </b-container>
  </header>
</template>

<script>
import gql from "graphql-tag";
import store from '../store/store';

export default {
  // eslint-disable-next-line
  name: "Menu",
  data() {
    return {
      menu: { Menu: [] },
      egg: false,
      eggCounter: 0,
      menuVisible: false,
      logoUrl: store.getConfig('general', 'logo'),
    };
  },
  methods: {
    toggleMenu() {
      this.menuVisible = !this.menuVisible;
    },
    handleEgg() {
      this.eggCounter++;
      if (this.eggCounter >= 3) {
        this.egg = true;
      }
    },
    handleMenuClick(action) {
      if (typeof this[action] === 'function') {
        this[action]();
      } else {
        console.warn(`Method ${action} is not defined.`);
      }
    },
  },
  apollo: {
    menu: {
      query: gql`
        query Menu {
          menu {
            Menu {
              id
              Title
              Slug
              onclick
            }
          }
        }
      `,
      update: (data) => data.menu,
    },
  },
};
</script>

<style lang="scss">
@use '../assets/scss/variables/breakpoints';
@use '../assets/scss/variables/misc';
@use '../assets/scss/variables/sizes';

#site-header {
  $headerHeight: 3.75rem;

  background-color: #fff;
  position: sticky;
  top: 0;
  width: 100vw;
  z-index: misc.$z-header;

  .egg-trigger {
    display: none;
    height: 100%;
    width: 20px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .header-navigation {
    align-items: center;
    display: flex;
    height: $headerHeight;
    justify-content: flex-end;
    padding: 0.875rem 0;

    .header-logo-container {
      display: block;
      flex-shrink: 0;
      height: calc(#{$headerHeight} - 1.75rem); // 100% of header with some whitespace, needs absolute value for image to respect its max-height
      margin-right: auto;
      overflow: hidden;

      &.contains-egg {
        overflow: visible;
      }

      img {
        display: block;
        max-height: 100%;
        width: auto;
      }
    }

    #header-menu-toggle {
      appearance: none;
      background-color: transparent;
      border: none;
      box-shadow: none;
      height: 1.5625rem;
      min-height: 0;
      padding: 0;
      outline: none;

      &.toggled-open {
        span {
          background-color: transparent;

          &::before {
            top: 0;
            transform: rotate(45deg);
          }

          &::after {
            top: 0;
            transform: rotate(-45deg);
          }
        }
      }

      span {
        $line-thickness: 0.3125rem;

        background-color: #000;
        display: block;
        height: $line-thickness;
        position: relative;
        width: 2.25rem;

        &::before,
        &::after {
          background-color: #000;
          content: '';
          height: $line-thickness;
          left: 0;
          position: absolute;
          transition: top 0.2s linear, transform 0.2s linear;
          width: 100%;
        }

        &::before {
          top: -10px;
        }

        &::after {
          bottom: -10px;
        }
      }
    }

    .header-menu-container {
      background-color: #000;
      display: none;
      height: calc(100vh - #{$headerHeight});
      left: 0;
      overflow-y: auto;
      padding: 5.6875rem 0;
      position: fixed;
      right: 0;
      top: $headerHeight;
      z-index: 3;

      &.menu-open {
        display: block;
      }

      svg.full-dots {
        height: 0.5rem;
        left: 0;
        position: absolute;
        top: 2.9375rem;
        width: auto;
      }

      .header-menu {
        display: flex;
        flex-direction: column;
        list-style: none;
        margin: 0;
        padding: 0;
        row-gap: 0.75rem;

        a,
        button {
          align-items: center;
          background-color: transparent;
          border: none;
          box-decoration-break: clone;
          box-shadow: none;
          color: #fff;
          display: inline-flex;
          font-size: 1.75rem;
          font-weight: bold;
          height: 100%;
          justify-content: center;
          padding: 0 3rem;
        }
      }

      .half-dots-container {
        background-color: #fff;
        bottom: 0;
        height: 0.625rem;
        left: 0;
        position: absolute;
        right: 0;

        svg.half-dots {
          bottom: calc(100% - 1px); // Ensure no seam is shown between the dots and the background-color
          height: 0.5rem;
          position: absolute;
          right: 1rem;
        }
      }
    }
  }

  @media #{breakpoints.$desktop-and-above} {
    $headerHeight: 6.25rem;

    .egg-trigger {
      display: block;
    }

    .header-navigation {
      height: $headerHeight;

      column-gap: sizes.$spacing-default;
      padding: 1.25rem 0;

      .header-logo-container {
        height: calc(#{$headerHeight} - 2.5rem); // 100% of header with some whitespace
      }

      #header-menu-toggle {
        display: none;
      }

      .header-menu-container {
        background-color: transparent;
        display: block;
        height: auto;
        padding: 0;
        position: static;

        svg.full-dots,
        .half-dots-container {
          display: none;
        }

        .header-menu {
          column-gap: 2.25rem;
          flex-direction: row;

          a,
          button {
            color: #000;
            font-size: 1.5rem;
            padding: 0;
            text-align: center;
          }
        }
      }
    }
  }
}

@keyframes tear {
  0% {transform: rotate(0);}
  10% {transform: rotate(0);}
  30% {transform: rotate(120deg);}
  50% {transform: rotate(80deg);}
  70% {transform: rotate(110deg);}
  90% {transform: rotate(100deg);}
  100% {transform: rotate(100deg);}
}

.oc-tearable {
  overflow: visible;
}
.oc-tear {
  animation-duration: 3s;
  animation-name: tear;
  animation-fill-mode: forwards;

  transform-origin: bottom left;
  animation-timing-function: ease-in-out;
  transform-box: fill-box;
}
.oc-tear-text {
  transform-origin: -7% 120%; /* Magic number alert! */
}
</style>
