<template>
  <div v-if="events.length">
    <b-container class="vp-desktop-no-padding">
      <b-row>
        <b-col cols="12" lg="8" offset-lg="2">
          <p class="vp-result-count vp-retain-margin">
            <template v-if="totalResults === 1">
              Er is <strong>1</strong> uitje gevonden
            </template>
            <template v-else>
              Er zijn <strong>{{ totalResults }}</strong> uitjes gevonden
            </template>
          </p>
        </b-col>
        <b-col cols="12" lg="8" offset-lg="2">
          <EventList :events="events" />
        </b-col>
      </b-row>
    </b-container>
  </div>
  <div v-else>
    <b-container class="vp-desktop-no-padding">
      <b-row>
        <b-col cols="12">
          <p class="vp-result-count vp-retain-margin">Er zijn geen uitjes gevonden</p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import EventList from '@/components/EventList.vue';

export default {
  name: 'SearchResults',
  components: {
    EventList,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    totalResults: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      config: {},
    };
  },
  created() {
    //this.config = store.state.config;
    this.config = {};
  },
};
</script>

<style lang="scss">
@use '../assets/scss/variables/sizes';
@use '../assets/scss/variables/type';

.vp-result-count {
  color: inherit;
  font-size: type.$fs-5xl;
  margin-bottom: sizes.$spacing-5xl;
}
</style>
