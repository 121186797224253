<template>
  <GMapMap :center="center" :zoom="zoom">
    <GMapCluster>
      <GMapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          @click="center=m.position"
      />
    </GMapCluster>
  </GMapMap>
</template>

<script>

export default {
  name: 'googleMap',
  data() {
    return {
      center: this.latlong,
      zoom: 16,
      markers: {
        0: {
          fullname: 'Test',
          position: {
            lat: this.latlong.lat,
            lng: this.latlong.lng,
          },
        },
      },
    };
  },
  props: {
    latlong: Object,
  },
};
</script>

<style lang="scss">
@use '../assets/scss/variables/sizes';

.vue-map-container {
  height: 0;
  margin-bottom: sizes.$spacing-2xl;
  padding-bottom: calc((9 / 16) * 100%); // 16 : 9
  position: relative;
  width: 100%;

  .vue-map {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
