<template>
  <div>
    <metainfo />
    <Navigation />
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>

<script>
import Navigation from "./components/Nav.vue";
import { useMeta } from 'vue-meta';

export default {
  name: "App",
  components: {
    Navigation,
  },
  setup () {
    useMeta({
      htmlAttrs: {
        lang: 'nl',
      },
    })
  },
};
</script>

<style lang="scss">
@use './assets/styles';
</style>
