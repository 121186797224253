<template>
  <button id="scroll-to-top" @click="scrollTop"></button>
</template>

<script>
export default {
  name: "ScrollToTop",
  methods: {
    scrollTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
  },
};
</script>

<style lang="scss">
@use '../assets/scss/variables/colors';
@use '../assets/scss/variables/sizes';
@use '../assets/scss/variables/breakpoints';

#scroll-to-top {
  align-items: center;
  appearance: none;
  background-color: colors.$brand-tertiary;
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  height: 4.625rem;
  justify-content: center;
  padding: 1.375rem;
  position: absolute;
  right: sizes.$spacing-sm;
  top: 0;
  transform: translatey(-50%);
  width: 4.625rem;
  z-index: 2;

  &::before {
    border-bottom: 1.875rem solid #fff;
    border-left: 0.9375rem solid transparent;
    border-right: 0.9375rem solid transparent;
    content: '';
  }

  @media #{breakpoints.$large-desktop-and-above} {
    right: 0;
  }
}
</style>
