<template>
  <div v-if="videos.length" class="vp-event-videos-list">
    <div v-for="(video, index) in videos" :key="index" class="vp-video-container">
      <iframe
        :src="'https://www.youtube.com/embed/' + video.youtubeCode"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'eventVideos',
  props: {
    videos: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
@use '../assets/scss/variables/sizes';

.vp-event-videos-list {
  margin-top: sizes.$spacing-6xl;

  .vp-video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    margin-bottom: sizes.$spacing-default;
    height: 0;
  }

  .vp-video-container iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
