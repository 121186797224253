<template>
	<footer id="site-footer">
    <svg class="full-dots d-none d-lg-block" width="497" height="17" viewBox="0 0 497 17" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 8.5 508 9" stroke="#00AAD2" stroke-width="16" fill="none" fill-rule="evenodd"
            stroke-dasharray="0,32" stroke-linecap="round"/>
    </svg>
    <svg class="full-dots d-block d-lg-none" width="169" height="9" viewBox="0 0 169 9" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 4.5h175" stroke="#00AAD2" stroke-width="8" fill="none" fill-rule="evenodd" stroke-dasharray="0,16" stroke-linecap="round"/>
    </svg>

    <b-container>
        <b-row>
          <b-col cols="12" class="scroll-top-col">
            <ScrollToTop />
          </b-col>
        </b-row>
        <b-row align-h="center">
          <b-col cols="9" md="8">
            <div class="footer-menus-container">
              <FooterMenu :menu="menu.Menu" />
              <FooterMenu :menu="footer.Menu" />
            </div>
          </b-col>
        </b-row>
      </b-container>

    <div class="bottom-footer-container">
      <b-container>
        <b-row align-h="center">
          <b-col cols="9" md="12">
            <FooterText :text="FooterText" :logo="logoUrl" />
          </b-col>
        </b-row>
      </b-container>
    </div>
	</footer>
</template>

<script>
import gql from "graphql-tag";
import store from '../store/store';
import FooterMenu from '@/components/FooterMenu.vue';
import FooterText from '@/components/FooterText.vue';
import ScrollToTop from '@/components/ScrollToTop.vue';

export default {
  // eslint-disable-next-line
	name: 'Footer',
  components: {
    FooterMenu,
    FooterText,
    ScrollToTop,
  },
  data() {
    return {
      menu: { Menu: [] },
      footer: { Menu: [] },
      FooterText: store.getConfig('footer', 'text'),
      logoUrl: store.getConfig('general', 'logo'),
    };
  },
  apollo: {
    menu: {
      query: gql`
      query Menu {
        menu {
          Menu {
            id
            Title
            Slug
            onclick
          }
        }
      }
    `,
      update: (data) => data.menu,
    },
    footer: {
      query: gql`
      query Footer {
        footer {
          Menu {
            id
            Title
            Slug
            onclick
          }
        }
      }
    `,
      update: (data) => data.footer,
    },
  },
};
</script>

<style lang="scss">
@use '../assets/scss/variables/breakpoints';
@use '../assets/scss/variables/sizes';

#site-footer {
  background-color: #000;
  position: relative;
  margin-top: 5.375rem;

  svg.full-dots {
    height: 0.5rem;
    left: 0;
    position: absolute;
    top: 2.9375rem;
    width: auto;
  }

  .scroll-top-col {
    position: relative;
  }

  .footer-menus-container {
    display: flex;
    flex-direction: column;
    padding: 5.6875rem 0 3.5rem;
    row-gap: sizes.$spacing-7xl;
  }

  .bottom-footer-container {
    --footer-text-bg: #fff; // Also used in FooterText.vue

    background-color: var(--footer-text-bg);
  }

  @media #{breakpoints.$tablet-and-above} {
    margin-top: sizes.$spacing-12xl;

    svg.full-dots {
      height: auto;
    }

    .footer-menus-container {
      padding: sizes.$spacing-13xl sizes.$spacing-8xl sizes.$spacing-8xl;
      column-gap: 8.625rem;
      flex-direction: row;
    }
  }
}
</style>
