<template>
  <div v-if="event.tickets">
    <div class="vp-ticket-block">
      <h3 v-if="event.title" class="vp-h3">
        <span v-if="event.isPostponed" class="vp-status">Uitgesteld</span>
        <span v-else-if="event.isCancelled" class="vp-status">Geannuleerd</span>
        <span v-else-if="event.isSoldOut" class="vp-status">Uitverkocht</span>
        <span>{{ event.title }}</span>
      </h3>

      <template v-if="event.tickets.length === 1">
        <p v-if="event.tickets[0].description">{{ event.tickets[0].description }}</p>
        <div class="vp-price-button-container">
          <span v-if="event.tickets[0].initialPrice" class="vp-price">
            Entree vanaf: {{ formatPrice(event.tickets[0].initialPrice) }}
          </span>
          <a
            v-if="event.tickets[0].url"
            :href="event.tickets[0].url"
            class="vp-button"
            target="_blank"
            @click="track(event, 'Koop ticket')"
          >
            Koop ticket
          </a>
        </div>
      </template>

      <template v-if="event.tickets.length != 1">
        <div v-if="event.location" class="vp-ticket-location">
          <!-- eslint-disable-next-line -->
          {{ event.location.name}}<span v-if="event.location.cityName">, {{ event.location.cityName }}</span>
        </div>
        <div class="vp-ticket-dates">
          <template v-if="event.startDate == event.endDate">
            {{ date(event.startDate) }}
          </template>
          <template v-else-if="event.startDate && event.endDate">
            {{ date(event.startDate) }} t/m {{ date(event.endDate) }}
          </template>
          <template v-else-if="event.startDate">
            Vanaf {{ date(event.startDate) }}
          </template>
          <template v-else-if="event.endDate">
            t/m {{ date(event.endDate) }}
          </template>
        </div>
      </template>

      <div v-if="event.isFree || event.adultFree" class="vp-ticket-prices">Gratis</div>
    </div>

    <template v-if="event.tickets.length > 1">
      <h3 class="vp-ticket-list-title vp-h3">Tickets</h3>
      <ul class="vp-ticket-list">
        <li
          v-for="(ticket, index) in event.tickets"
          :key="index"
          class="vp-ticket"
        >
          <div v-if="ticket.title || ticket.description" class="vp-ticket-meta">
            <div v-if="ticket.title" class="vp-ticket-title" v-html="ticket.title" />
            <p
              v-if="ticket.description"
              class="vp-ticket-description"
              v-html="ticket.description"
            />
          </div>
          <a
            v-if="ticket.url"
            :href="ticket.url"
            class="vp-button"
            @click="track(event, 'Koop ticket')"
          >
            Koop ticket
          </a>
        </li>
      </ul>
    </template>
  </div>
</template>

<script>
import mixin from '@/mixins/mixin.js';

export default {
  name: 'eventTicketBlock',
  mixins: [mixin],
  props: {
    event: Object,
  },
  methods: {
    date(date) {
      return this.formatDate(date, 'dddd D MMMM', 'dddd D MMMM YYYY');
    },
  },
};
</script>

<style scoped lang="scss">
@use '../assets/scss/variables/breakpoints';
@use '../assets/scss/variables/colors';
@use '../assets/scss/variables/sizes';
@use '../assets/scss/variables/type';

.vp-ticket-list-title {
  font-size: type.$fs-3xl;
  font-weight: type.$fw-medium;
  line-height: type.$lh-2xs;
  margin: -#{sizes.$spacing-3xl} 0 0;
  padding: sizes.$spacing-3xs sizes.$spacing-2xl sizes.$spacing-xs;
  position: relative; // Neede for z-index
  z-index: 2;
}

.vp-ticket-list {
  margin-bottom: sizes.$spacing-5xl;

  .vp-button {
    display: block;
    text-transform: uppercase;
  }

  .vp-ticket {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: sizes.$spacing-xs sizes.$spacing-default sizes.$spacing-sm sizes.$spacing-2xl;

    &:nth-child(odd) {
      background-color: colors.$brand-primary;
      color: colors.$brand-primary-text;
    }

    &:nth-child(even) {
      background-color: colors.$body-bg-color;
      color: colors.$text-color;
    }

    @media #{breakpoints.$mobile-and-above} {
      align-items: center;
      flex-direction: row;

      .vp-button {
        margin: 0;
      }
    }
  }
}

@media #{breakpoints.$small-mobile-and-above} {
  .vp-button {
    margin: sizes.$spacing-default 0 0 0;
  }
  .vp-ticket-meta {
    .vp-ticket-description {
      margin: sizes.$spacing-default 0 0 0;
    }

    .vp-ticket-title {
      font-size: type.$fs-regular;
      font-weight: type.$fw-bold;
      line-height: type.$lh-sm;
    }
  }
}

.vp-ticket-block {
  background-color: colors.$brand-primary;
  color: colors.$brand-primary-text;
  margin-bottom: sizes.$spacing-3xs;
  padding: sizes.$spacing-2xl sizes.$spacing-2xl sizes.$spacing-9xl;
  position: relative;

  &::before {
    background-color: colors.$body-bg-color;
    bottom: 0;
    content: '';
    display: block;
    height: 1.5rem;
    left: 0;
    position: absolute;
    width: 100%;
  }

  &::after {
    border-right: 1.25rem solid transparent;
    border-top: 1.5rem solid colors.$brand-primary;
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
  }

  .vp-h3,
  .vp-ticket-prices {
    font-size: type.$fs-regular;
    font-weight: type.$fw-bold;
    line-height: type.$lh-sm;
  }

  .vp-h3 {
    color: inherit;
    margin-bottom: sizes.$spacing-3xs;

    .vp-status {
      color: colors.$danger;

      &::after {
        content: '|';
        margin: 0 0.5ch;
        color: colors.$brand-primary-text;
      }
    }
  }

  .vp-ticket-dates,
  .vp-ticket-location {
    color: colors.$grey-alt;
    font-size: type.$fs-xs;
    line-height: type.$lh-sm;
  }

  .vp-ticket-prices {
    margin-top: sizes.$spacing-3xs;

    .vp-ticket-adult-price {
      margin-bottom: sizes.$spacing-sm;
    }
  }

  .vp-price-button-container {
    align-items: center;
    display: flex;
    justify-content: space-between;

    .vp-price {
      font-size: type.$fs-4xl;
      font-weight: type.$fw-medium;
      line-height: type.$lh-sm;
    }
  }

  @media #{breakpoints.$tablet-and-above} {
    .vp-h3,
    .vp-ticket-prices {
      font-size: type.$fs-4xl;
      font-weight: type.$fw-medium;
      line-height: type.$lh-regular;
    }

    .vp-h3 {
      margin-bottom: sizes.$spacing-xs;
    }

    .vp-ticket-dates,
    .vp-ticket-location {
      font-size: type.$fs-lg;
      line-height: type.$lh-lg;
    }

    .vp-ticket-prices {
      margin-top: sizes.$spacing-default;
    }
  }
}
</style>
